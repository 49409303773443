:root {
    --accent: 136, 58, 234;
    --accent-light: rgb(162, 203, 252);
    --accent-dark: 49, 10, 101;
    --accent-gradient: linear-gradient( 45deg, rgb(60, 146, 252), rgb(54, 54, 255) 30%, rgb(115, 129, 255) 90% );
}

body {  
    margin: 0; 
    zoom: 1;
    background-color: blanchedalmond;
}

#treediv{
    margin: 0em 0 0;
    padding-top: 1em;
    overflow: visible;
}

.tf-tree{
    overflow:hidden;
    /*border:rgb(43, 255, 0) solid 2px;*/
    display: inline-flex;
    position:relative;
    margin: 2em 0 0 0;
    white-space: wrap;
    top:0px;
    padding-top:2em;
    flex-flow: column wrap;
}

.tf-nc {
    border:rgb(228, 12, 192) solid 2px;
    transition: background-position 0.6s cubic-bezier(0.22,1,0.36,1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    color:rgba(0,0,0,1);
}

/*.tf-nc.the-duplicate {
    width:20px;
    height:20px;
    border-radius: 12;
    color:rgba(0,0,0,0);
}*/
  
/*
.tf-tree li ul {
    margin: 2em 0;
    border:red solid 1px;
    border:rgb(241, 219, 20) solid 2px;
    border:rgb(228, 12, 192) solid 2px;
}*/

.tf-tree ul li > div:first-child{
    display:flex;
    background-color: rgb(10, 241, 10);
}

/*.top:before{
//    display:none !important;
}*/

.tf-tree li:has(.top):before{
    display:none !important;
}

span.tf-nc.ancestor{
    background-color: rgba(69, 160, 114, 0.296); 
}

/*span.child.ancestor::before{
    position: relative;
    display: block;
    border: 1px solid red;
    transform: scale(2.02);
    background-color: grey;
    top: -2px;
    width: 50px;
    left: 97px;
    height: 1px;
}*/
/*
span.child{
    background-color: #ffffff;
    border: .0625em solid #000;
    padding: .5em 1em;
    display: inline-block;
    position: relative;
}

span.child:first-child:after{
    content: "";
    border-left: red solid 1px;
    position: relative;
    left: -50%;
    top: -1.2em;
}
span.child:last-of-type:after{
    content: "";
    border-left: red solid 1px;
    position: relative;
    left: -50%;
    bottom: -1.29em;
}*/

.truncated{
    position: absolute;
    bottom: -10px;
    left: 0;
    width:10px;
    height:10px;
    /*border-radius: 50%;
    content:'T';
    padding: 2px 3px;
    background-color: rgb(252, 252, 254);
    color: white;
    border: solid 1px black;*/
    background-color:rgba(0, 0, 0, 0);
    color: #C11C39;
    border: none;
    font-size: 10px;
    z-index: 1;
    cursor:pointer;
  }

span.child:not(.ancestor){
    background-color: #fcdaa692;    
}

.tf-tree.cob span[data-cob=""]:not(.child):not(.ancestor){background-color: rgba(255, 255, 255, 0);color:red}
.tf-tree.cob span[data-cob="LightBlue"]:not(.child){background-color: LightBlue;}
.tf-tree.cob span[data-cob="Cyan"]:not(.child){background-color: Cyan;}
.tf-tree.cob span[data-cob="Blue"]:not(.child){background-color: Blue;color:aliceblue;}
.tf-tree.cob span[data-cob="Orange"]:not(.child){background-color: Orange;}
.tf-tree.cob span[data-cob="Red"]:not(.child){background-color: Red;}
.tf-tree.cob span[data-cob="Purple"]:not(.child){background-color: Purple;color:aliceblue;}
.tf-tree.cob span[data-cob="Green"]:not(.child){background-color: Green;color:aliceblue;}
.tf-tree.cob span[data-cob="#C11C39"]:not(.child){background-color: #C11C39;color:aliceblue;}
.tf-tree.cob span[data-cob="rgb(250, 182, 233)"]:not(.child){background-color: rgb(228, 35, 250);}
.tf-tree.cob span[data-cob="Magenta"]:not(.child){background-color: Magenta;}
.tf-tree.cob span[data-cob="LightYellow"]:not(.child){background-color: LightYellow;color:green;}
.tf-tree.cob span[data-cob="Brown"]:not(.child){background-color: Brown;color:aliceblue;}
.tf-tree.cob span[data-cob="rgb(117, 255, 209)"]:not(.child){background-color: rgb(42, 159, 165);color:aliceblue;}
.tf-tree.cob span[data-cob="rgb(117, 255, 199)"]:not(.child){background-color: rgb(21, 202, 160);color:aliceblue;}
.tf-tree.cob span[data-cob="rgb(117, 255, 240)"]:not(.child){background-color: rgb(34, 113, 104);color:aliceblue;}


/*.tf-tree.source span:not(.truncated){color: rgba(1, 1, 1, 1);border:rgb(0, 0, 0) solid 1px; background-color: rgba(196, 220, 255, 0.5);}*/
.tf-tree.source span[data-owner="user1@demo.local"]{color: rgb(8, 8, 8);border:rgb(72, 255, 0) solid 1px;}
.tf-tree.source span[data-owner="user2@demo.local"]{color: rgba(0, 251, 255, 0.5);border:rgb(0, 251, 255) solid 1px;}
.tf-tree.source span[data-owner="user3@demo.local"]{color: rgba(0, 64, 255, 0.5);border:rgb(0, 64, 255) solid 1px;}
/*.tf-tree.source span[data-owner="underwmw@hotmail.com"]{color: rgba(157, 0, 255, 0.5);border:rgb(157, 0, 255) solid 1px;}*/

.tf-tree.source span[data-realm="rlm-public"]{color: rgb(1, 1, 1);border:rgb(191, 138, 64) solid 1px; background-color: rgba(184, 165, 138, 0.904);}
.tf-tree.source span[data-realm="unauthorized"]{color: rgb(1, 1, 1);border:rgb(184, 42, 63) solid 1px; background-color: rgba(212, 108, 124, 0.904);}
.tf-tree.source span[data-realm="server-json"]{color: rgba(0, 0, 0, 1);border:rgba(189, 154, 105, 0.566) solid 1px; background-color: rgba(189, 154, 105, 0.65);}
.tf-tree.source span[data-realm="user1@demo.local"]{color: rgba(0, 0, 0, 1);border:rgb(55, 255, 0) solid 1px; background-color: rgba(89, 224, 82, 0.0.25);}
.tf-tree.source span[data-realm="user2@demo.local"]{color: rgba(1, 1, 1);border:rgb(0, 85, 255) solid 1px; background-color: rgba(162, 192, 253, 0.514);}
.tf-tree.source span[data-realm="user3@demo.local"]{color: rgba(187, 0, 255, 1);border:rgb(187, 0, 255) solid 1px;}
/*.tf-tree.source span[data-realm="underwmw@hotmail.com"]{color: rgba(223, 25, 200, 1);border:rgb(223, 25, 200) solid 1px; background-color: rgba(182, 138, 184, 0.904);}*/


.tf-tree.sex span[data-sex="1"]{background-color: lightblue;}
.tf-tree.sex span[data-sex="2"]{background-color: lightpink;}

.tf-tree.descendant span[data-descendant="true"]{background-color: rgb(199, 255, 212);}

span{
    cursor: pointer;
    padding:0.1em !important;
    border-radius: 3px;
    /*--data-color: var(attr(data-cob));
    min-width: 100px;
    max-width: 150px;*/
}

.tf-tree li>span.child:not(:last-of-type):after{
    border:none !important;
    /*background-color: rgb(255, 182, 245) !important; */
}

.tf-tree li>span.child:not(:only-of-type):not(:nth-child(0)):before{
    border:none !important;
    /*background-color: rgb(255, 182, 245) !important; */
}

.tf-nc.selected{
    background-color: #e6e20d85 !important;
  }

.tf-nc:is(:hover,:focus-within):not(.selected) {
    background-position: 0;
    background-image: var(--accent-gradient);
}

.xtf-tree.cob span{background-color: var(--data-color) !important;}

cs{
    display:inline-flex;
}
cs.error{
    border:red 2px solid;
}

csp{
    display:inline-flex;
}
csp.error{
    border:red 2px solid;
}

us.url{
    position: absolute;
    /*top: 0px;*/
    left: 100%;
    padding: 0px 1px 2px 2px;
    border-radius: 4px 4px 4px 4px;
    line-height:8px;
    background-color: rgb(26, 84, 102);
    color: white;
    font-size: 12px;
    min-width: 74px;
  }
us.url:hover:after{
    content:attr(data-url);
    position: absolute;
    top:-0px;
    left:calc(100%+40px);
    width:500px;
    color:black;
    background-color: White;
    z-index: 1;
}  

.nav-child-check{
    position: absolute;
    top: -10px;
    right: calc(20%);
    padding: 2px 3px;
    border-radius: 50%;
    background-color: rgb(223, 250, 185);
    color: rgb(4, 4, 4);
    /*border: solid 1px black;*/
    font-size: 12px;
}  

.nav-children{
    position: absolute;
    top: -10px;
    right: calc(50%);
    padding: 1px 2px 1px 1px;
    border-radius: 50%;
    background-color: black;
    color: white;
    /*border: solid 1px black;*/
    font-size: 10px;
  }

  .ds{
    position: absolute;
    bottom: -10px;
    right: calc(50% - 5px);
    width:10px;
    height:10px;
    border-radius: 50%;
    padding: 2px 3px;
    background-color: rgba(252, 252, 254, 0);
    color: white;
    border: solid 1px black;
    font-size: 12px;
    z-index: 1;
    cursor:pointer;
  }

.depthSpan{
    position: absolute;
    top: -14px;
    right: -14px;
    width:15px;
    height:15px;
    border-radius: 50%;
    padding: 1px 4px !important;
    background-color: rgba(252, 252, 254, 0.3);
    color: rgb(5, 5, 5);
    border: solid 1px rgba(0, 0, 0, 0);
    font-size: 12px;
    z-index: 1;
    cursor:pointer;
}
.depthSpan:hover:after{
    color:black;
    background-color: White;
    z-index: 2;
}  

.more{
 background-color: rgb(0, 0, 0);
  } 

.xnav-children::before {
    position:absolute;
    top:-2em;
    left: calc(50% - 0.7em);
    cursor: pointer;
    padding:0.1em !important;
    content:'\261D';/*'\26F9';/*'\2764';*/
}
 
.popup{
    position:fixed;
    left:0;
    top:20px;
    width:250px;
    white-space: wrap;
    overflow:hide;
    z-index: 2;
    display: none;
    background-color: antiquewhite;
    padding:0.1em;
    margin: 0.1em;
    border:black solid 2px;
    border-radius: 4px;
}

.control{
    position:fixed;
    left:0;
    white-space: nowrap;
    z-index: 1;
    border-radius: 4px;
}

.updated{
    border: solid #C11C39 2px !important;
}

/*span.tf-nc:not(.ancestor){
    background-color: rgba(227, 254, 202, 0.363);
    background-color: rgba(255, 186, 101, 0);
}*/

.male{
    background-color: rgb(164, 244, 247);
}

.female{
    background-color: rgb(250, 182, 233);
}

div#mySlider{
    display:inline-block;
    position:relative;
}

div#mySlider::before {
    content: '';
    position: absolute;
    top: 2px; /* Adjust this value to control the vertical position */
    left: 0;
    right: 0;
    height: 80%; /* Adjust this value to control the height */
    background-color: rgba(108, 255, 255, 0.3);
    z-index: -1; /* Ensure the background is behind the text */
}

.depth{
    display:inline;
    position:relative;
    top:1px;
    border:none;
}

.depth span:hover{
    border:solid black 1px;
}

.depth span[selected]{
    border:solid black 1px;
    background-color: aqua;
}

div#myScale{
    background-color: rgba(108, 255, 255, 0.3);
    border:rgba(192, 255, 253, 0.503) solid 1px;
    display:inline-block;
}

.scale{
    display:inline;
    position:relative;
    top:1px;
    border:none;
}

.scale span:hover{
    border:solid black 1px;
}

.scale span[selected]{
    border:solid black 1px;
    background-color: aqua;
}

textarea{
    display:flex;
}

.search{
    width:250px;
}
textarea.search{
    width:300px;
}

.root{
    width:250px;
    cursor: pointer;
}

/*.tf-nc[xdata-tippy-content]:hover::after {
    content: attr(data-tippy-content);
    position: absolute;
    display:flex;/*contents/
    border:0px;
    white-space:unset;
    flex-wrap: wrap;
    width: fit-content;
    min-width: 400px;
    Height: fit-content;
    bottom: 0;
    left: -100px;
    /*border:red solid 3px;/
    background-color: white;
    padding:0 0 0 0;
    margin:0 0 0 0;
    z-index:1;
    color: #111; 
    font-size: 100%;
    white-space: wrap;
    /*box-shadow: 1px 1px 3px #222222;/
    opacity: 1;
    overflow:auto;
  }

  .xtf-nc[data-title]:hover:after {
    content: attr(data-title);
    background-color: #00FF00;
    color: #111; 
    font-size: 150%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
}*/

.tf-child{
    border:solid black 1px;
    display: block;
}

.group{
    display:inline-block;
}

.list{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
}

span.tf-nc.duplicate::after{
/*position:relative;*/
left:-4px;
top:-4px;
background-color: #9900cc4a;
width:calc(100% + 8px);
height:calc(100% + 8px);
z-index:-1;
/*content:attr(data-uid);
    /*border: #C11C39 solid 4px;*/
    /*min-width: 250px;*/
content: "\00A0"; /* Ensure there is always content */
display: inline-block; /* Force it to render even without children */
}

.heading{
    display:inline-flex;
    align-items: center;
    background-color: #aeaeae;
    text-align: center;
    border: solid darkgray 1px;
}

.small-button{
    width:35px;
    height:16px;
    font-size: 8px;
    padding:1px;
    margin-left: 5px;
}

#uid-group{
    position:absolute;
    display:flex;
    flex-direction: row;
    width: fit-content;
    border:solid green 1px;
    background-color: #aeaeaec8;
    z-index:1;
}

#manage-user{
    position:absolute;
    top:0;
    right:0;
}

#manage-user-anchor{
    position:absolute;
    /*bottom:0;*/
    display:flex;
    flex-direction: row;
    width: fit-content;
    border:solid green 1px;
    background-color: #aeaeaec8;
    z-index:1;
}

#uid-group.hidden{
    display:none;
}

#uid-group input{
    display:flex;
}

#uid-group .selected{
    background-color: #dddddd;
}

.detail-list{
    display:flow;
    flex-direction: column;
}

#test-group{
    display:inline;
}

.draggable{
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }

.slider-draggable {
    /*position: absolute; /* Enable positioning */
    cursor: grabbing; /* Change cursor on drag */
}

.draggable-clone {
    position: absolute; /* Enable positioning */
    cursor: grabbing; /* Change cursor on drag */
    background-color: rgba(108, 255, 255, 0.3); /* Make it visible */
    pointer-events: none; /* Prevent interaction with the clone */
}

#load-btn{
    background-color:white;
}

#load-btn.loaded{
    background:linear-gradient(to right,#00ff00, #00cc00, #009900,#6600cc, #9900cc, #cc00cc, #ff00ff);
}

.not-in-data{
    background-color: rgba(255, 140, 140, 0.338);
}

.tooltip {
        display: inline-block;
        padding: 3px 6px;
        background-color: #f1f4dc;
        border-radius: 4px;
        cursor: pointer;
    } 
/*  
.child-container{
    display: flex !important;
    flex-direction: column;
} 

.tf-child:before{
    display:none !important;
}

.tf-child:after{
    display:none !important;
}*/



.hidden{display:none;}
span.error{border:red 1px solid !important}
span.foundElsewhere{border:green 2px solid !important}

#drop-zone{
    display:inline-block;
    position: relative;
    top:3px;
    width:50px;
    height:16px;
    background-color: #3b234259;
}

.outer-container {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    overflow: auto;
  }

.scaling-wrapper {
    display: inline-block; /* Important to prevent flexbox auto-resizing issues */
    transform: scale(0.6);
    transform-origin: top left;
    width: 100%;
    height: 100%;
  }

pre{
    padding:0;
    margin:0;
}  
  
.xinner-content {
    overflow: auto;
}

.w2ui-msg-text{
    align-items: left !important;
    display:contents !important;
}

#edit-form{
    display: block;
    position: absolute;
    top: 400px;
    left:0;
    width: 750px;
    height: 300px;
    z-index:1;
}
.action-div{
    position: absolute;
    top: -2px;
}
span.tf-nc .action-div{
    overflow:hidden;
}
.action-div{
    position:relative;
    display:block;
    margin:0;
    padding:0;
    list-style: none;
    text-align: center;
    padding-top: 5px;
}

.male{
    background-color:lightblue !important;
}
.female{
    background-color: lightpink !important;
}
/* next three for grid*/
.unauthorized{
    background-color: rgb(252, 86, 86, 0.322) !important;
}

.rlm-public{
    background-color: rgba(163, 96, 20, 0.322) !important;
}

.server-json{
    background-color:rgba(255, 166, 0, 0.189) !important;
}

.w2ui-icon-info:before {
    position: relative;
    top: -0px;
}

.w2ui-tb-button:hover{
    background-color: #a3a3a385 !important;
  }

.w2ui-tb-button.checked{
    background-color: #b3aeb2 !important;
  }

.w2ui-icon-reload{
    overflow: visible !important;
    position: relative !important;
    top: -15px !important;
}  

/*
.w2ui-grid .w2ui-grid-body table td.w2ui-grid-data > div {
        white-space: unset;
}

.row{
    height:24px !important;
   
}*/
/*

.w2ui-grid-data div{
    overflow: visible !important;
    position: relative !important;
    top: -3px !important;
}

.w2ui-input.hidden{
    display:none !important;
}

.w2ui-input+.fa-unlink{
    cursor:pointer !important;
    padding-left:4px !important;
}

/*#form_settingsForm_tabs~div .w2ui-field label{
    /*class="w2ui-field w2ui-span6"
    background-color: rgb(250, 200, 126);
    border-radius: 4px;
    min-width:300px !important;
}
#form_editItem_tabs~div .w2ui-field label{
    max-width:60px !important;
}
.w2ui-field.w2ui-span6 > div{
    margin-left:60px !important;
}
#form_editItem_tabs~div .w2ui-field textarea{
    width:400px !important;
}

#form_editItem_tabs~div .w2ui-field:nth-child(1)  textarea{
    height:150px !important;
}

#form_editItem_tabs~div .w2ui-field input{
    width:380px !important;
}

.w2ui-map-container{
    background-color: #a680cb30 !important;
}*/

i.x{
    /*content: "d7";/*X*/
    box-sizing: inherit;
    bottom:-21px;
    right: calc(50% - 25px);
    font-size: 1.5em;
    line-height: 1;
    position: absolute;
    color: #8a8a8a;
    cursor: pointer;
}

.add-parent{
    cursor:pointer;
}
.add-parent-input{
    width:380px;
}
.add-child, .fa-unlink, .fa-plus{
    cursor:pointer;
}
.add-child-input{
    width:380px;
}

.busy{
    background-color: rgba(128, 128, 128, 0.498) !important;
}

/*https://data-flair.training/blogs/css-arrows/#google_vignette*/
.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    }
.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
    }
.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid black;
    border-bottom: 10px solid transparent;
    }
.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid black;
    border-bottom: 10px solid transparent;
    }
    /*****************************************************************************/
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    background-color: #00990088;
    }

#loader-counter:has(.loader){
    position: absolute;
    z-index: 200;
    left: 40%;
    top: 10%;
    }
#loader-counter::after{
    content : attr(data-depth);
    z-index: 201;
    position:relative;
    top: -101px;
    left: 10px;
    font-size: 42;
}

      
      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }