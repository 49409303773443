:root {
  --accent: 136, 58, 234;
  --accent-light: #a2cbfc;
  --accent-dark: 49, 10, 101;
  --accent-gradient: linear-gradient(45deg, #3c92fc, #3636ff 30%, #7381ff 90%);
}

body {
  zoom: 1;
  background-color: #ffebcd;
  margin: 0;
}

#treediv {
  margin: 0;
  padding-top: 1em;
  overflow: visible;
}

.tf-tree {
  white-space: wrap;
  flex-flow: column wrap;
  margin: 2em 0 0;
  padding-top: 2em;
  display: inline-flex;
  position: relative;
  top: 0;
  overflow: hidden;
}

.tf-nc {
  color: #000;
  border: 2px solid #e40cc0;
  transition: background-position .6s cubic-bezier(.22, 1, .36, 1);
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.tf-tree ul li > div:first-child {
  background-color: #0af10a;
  display: flex;
}

.tf-tree li:has(.top):before {
  display: none !important;
}

span.tf-nc.ancestor {
  background-color: #45a0724b;
}

.truncated {
  color: #c11c39;
  z-index: 1;
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 10px;
  height: 10px;
  font-size: 10px;
  position: absolute;
  bottom: -10px;
  left: 0;
}

span.child:not(.ancestor) {
  background-color: #fcdaa692;
}

.tf-tree.cob span[data-cob=""]:not(.child):not(.ancestor) {
  color: red;
  background-color: #fff0;
}

.tf-tree.cob span[data-cob="LightBlue"]:not(.child) {
  background-color: #add8e6;
}

.tf-tree.cob span[data-cob="Cyan"]:not(.child) {
  background-color: #0ff;
}

.tf-tree.cob span[data-cob="Blue"]:not(.child) {
  color: #f0f8ff;
  background-color: #00f;
}

.tf-tree.cob span[data-cob="Orange"]:not(.child) {
  background-color: orange;
}

.tf-tree.cob span[data-cob="Red"]:not(.child) {
  background-color: red;
}

.tf-tree.cob span[data-cob="Purple"]:not(.child) {
  color: #f0f8ff;
  background-color: purple;
}

.tf-tree.cob span[data-cob="Green"]:not(.child) {
  color: #f0f8ff;
  background-color: green;
}

.tf-tree.cob span[data-cob="#C11C39"]:not(.child) {
  color: #f0f8ff;
  background-color: #c11c39;
}

.tf-tree.cob span[data-cob="rgb(250, 182, 233)"]:not(.child) {
  background-color: #e423fa;
}

.tf-tree.cob span[data-cob="Magenta"]:not(.child) {
  background-color: #f0f;
}

.tf-tree.cob span[data-cob="LightYellow"]:not(.child) {
  color: green;
  background-color: #ffffe0;
}

.tf-tree.cob span[data-cob="Brown"]:not(.child) {
  color: #f0f8ff;
  background-color: brown;
}

.tf-tree.cob span[data-cob="rgb(117, 255, 209)"]:not(.child) {
  color: #f0f8ff;
  background-color: #2a9fa5;
}

.tf-tree.cob span[data-cob="rgb(117, 255, 199)"]:not(.child) {
  color: #f0f8ff;
  background-color: #15caa0;
}

.tf-tree.cob span[data-cob="rgb(117, 255, 240)"]:not(.child) {
  color: #f0f8ff;
  background-color: #227168;
}

.tf-tree.source span[data-owner="user1@demo.local"] {
  color: #080808;
  border: 1px solid #48ff00;
}

.tf-tree.source span[data-owner="user2@demo.local"] {
  color: #00fbff80;
  border: 1px solid #00fbff;
}

.tf-tree.source span[data-owner="user3@demo.local"] {
  color: #0040ff80;
  border: 1px solid #0040ff;
}

.tf-tree.source span[data-realm="rlm-public"] {
  color: #010101;
  background-color: #b8a58ae7;
  border: 1px solid #bf8a40;
}

.tf-tree.source span[data-realm="unauthorized"] {
  color: #010101;
  background-color: #d46c7ce7;
  border: 1px solid #b82a3f;
}

.tf-tree.source span[data-realm="server-json"] {
  color: #000;
  background-color: #bd9a69a6;
  border: 1px solid #bd9a6990;
}

.tf-tree.source span[data-realm="user1@demo.local"] {
  color: #000;
  background-color: #59e05240;
  border: 1px solid #37ff00;
}

.tf-tree.source span[data-realm="user2@demo.local"] {
  color: #010101;
  background-color: #a2c0fd83;
  border: 1px solid #05f;
}

.tf-tree.source span[data-realm="user3@demo.local"] {
  color: #b0f;
  border: 1px solid #b0f;
}

.tf-tree.sex span[data-sex="1"] {
  background-color: #add8e6;
}

.tf-tree.sex span[data-sex="2"] {
  background-color: #ffb6c1;
}

.tf-tree.descendant span[data-descendant="true"] {
  background-color: #c7ffd4;
}

span {
  cursor: pointer;
  border-radius: 3px;
  padding: .1em !important;
}

.tf-tree li > span.child:not(:last-of-type):after, .tf-tree li > span.child:not(:only-of-type):not(:nth-child(0)):before {
  border: none !important;
}

.tf-nc.selected {
  background-color: #e6e20d85 !important;
}

.tf-nc:is(:hover, :focus-within):not(.selected) {
  background-position: 0;
  background-image: var(--accent-gradient);
}

.xtf-tree.cob span {
  background-color: var(--data-color) !important;
}

cs {
  display: inline-flex;
}

cs.error {
  border: 2px solid red;
}

csp {
  display: inline-flex;
}

csp.error {
  border: 2px solid red;
}

us.url {
  color: #fff;
  background-color: #1a5466;
  border-radius: 4px;
  min-width: 74px;
  padding: 0 1px 2px 2px;
  font-size: 12px;
  line-height: 8px;
  position: absolute;
  left: 100%;
}

us.url:hover:after {
  content: attr(data-url);
  top: 0;
  left: calc(100%40px);
  color: #000;
  z-index: 1;
  background-color: #fff;
  width: 500px;
  position: absolute;
}

.nav-child-check {
  color: #040404;
  background-color: #dffab9;
  border-radius: 50%;
  padding: 2px 3px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 20%;
}

.nav-children {
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  padding: 1px 2px 1px 1px;
  font-size: 10px;
  position: absolute;
  top: -10px;
  right: 50%;
}

.ds {
  color: #fff;
  z-index: 1;
  cursor: pointer;
  background-color: #fcfcfe00;
  border: 1px solid #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 2px 3px;
  font-size: 12px;
  position: absolute;
  bottom: -10px;
  right: calc(50% - 5px);
}

.depthSpan {
  color: #050505;
  z-index: 1;
  cursor: pointer;
  background-color: #fcfcfe4d;
  border: 1px solid #0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 12px;
  position: absolute;
  top: -14px;
  right: -14px;
  padding: 1px 4px !important;
}

.depthSpan:hover:after {
  color: #000;
  z-index: 2;
  background-color: #fff;
}

.more {
  background-color: #000;
}

.xnav-children:before {
  cursor: pointer;
  content: "☝";
  position: absolute;
  top: -2em;
  left: calc(50% - .7em);
  padding: .1em !important;
}

.popup {
  width: 250px;
  white-space: wrap;
  overflow: hide;
  z-index: 2;
  background-color: #faebd7;
  border: 2px solid #000;
  border-radius: 4px;
  margin: .1em;
  padding: .1em;
  display: none;
  position: fixed;
  top: 20px;
  left: 0;
}

.control {
  white-space: nowrap;
  z-index: 1;
  border-radius: 4px;
  position: fixed;
  left: 0;
}

.updated {
  border: 2px solid #c11c39 !important;
}

div#mySlider {
  display: inline-block;
  position: relative;
}

div#mySlider:before {
  content: "";
  z-index: -1;
  background-color: #6cffff4d;
  height: 80%;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
}

.depth {
  border: none;
  display: inline;
  position: relative;
  top: 1px;
}

.depth span:hover {
  border: 1px solid #000;
}

.depth span[selected] {
  background-color: #0ff;
  border: 1px solid #000;
}

div#myScale {
  background-color: #6cffff4d;
  border: 1px solid #c0fffd80;
  display: inline-block;
}

.scale {
  border: none;
  display: inline;
  position: relative;
  top: 1px;
}

.scale span:hover {
  border: 1px solid #000;
}

.scale span[selected] {
  background-color: #0ff;
  border: 1px solid #000;
}

textarea {
  display: flex;
}

.search {
  width: 250px;
}

textarea.search {
  width: 300px;
}

.root {
  cursor: pointer;
  width: 250px;
}

.tf-child {
  border: 1px solid #000;
  display: block;
}

.group {
  display: inline-block;
}

.list {
  flex-flow: column wrap;
  align-content: center;
  display: flex;
}

span.tf-nc.duplicate:after {
  z-index: -1;
  content: " ";
  background-color: #9900cc4a;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  display: inline-block;
  top: -4px;
  left: -4px;
}

.heading {
  text-align: center;
  background-color: #aeaeae;
  border: 1px solid #a9a9a9;
  align-items: center;
  display: inline-flex;
}

.small-button {
  width: 35px;
  height: 16px;
  margin-left: 5px;
  padding: 1px;
  font-size: 8px;
}

#uid-group {
  z-index: 1;
  background-color: #aeaeaec8;
  border: 1px solid green;
  flex-direction: row;
  width: fit-content;
  display: flex;
  position: absolute;
}

#manage-user {
  position: absolute;
  top: 0;
  right: 0;
}

#manage-user-anchor {
  z-index: 1;
  background-color: #aeaeaec8;
  border: 1px solid green;
  flex-direction: row;
  width: fit-content;
  display: flex;
  position: absolute;
}

#uid-group.hidden {
  display: none;
}

#uid-group input {
  display: flex;
}

#uid-group .selected {
  background-color: #ddd;
}

.detail-list {
  flex-direction: column;
  display: block;
}

#test-group {
  display: inline;
}

.draggable {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.slider-draggable {
  cursor: grabbing;
}

.draggable-clone {
  cursor: grabbing;
  pointer-events: none;
  background-color: #6cffff4d;
  position: absolute;
}

#load-btn {
  background-color: #fff;
}

#load-btn.loaded {
  background: linear-gradient(to right, #0f0, #0c0, #090, #60c, #90c, #c0c, #f0f);
}

.not-in-data {
  background-color: #ff8c8c56;
}

.tooltip {
  cursor: pointer;
  background-color: #f1f4dc;
  border-radius: 4px;
  padding: 3px 6px;
  display: inline-block;
}

.hidden {
  display: none;
}

span.error {
  border: 1px solid red !important;
}

span.foundElsewhere {
  border: 2px solid green !important;
}

#drop-zone {
  background-color: #3b234259;
  width: 50px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.outer-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.scaling-wrapper {
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  transform: scale(.6);
}

pre {
  margin: 0;
  padding: 0;
}

.xinner-content {
  overflow: auto;
}

.w2ui-msg-text {
  align-items: left !important;
  display: contents !important;
}

#edit-form {
  z-index: 1;
  width: 750px;
  height: 300px;
  display: block;
  position: absolute;
  top: 400px;
  left: 0;
}

.action-div {
  position: absolute;
  top: -2px;
}

span.tf-nc .action-div {
  overflow: hidden;
}

.action-div {
  text-align: center;
  margin: 0;
  padding: 5px 0 0;
  list-style: none;
  display: block;
  position: relative;
}

.male {
  background-color: #add8e6 !important;
}

.female {
  background-color: #ffb6c1 !important;
}

.unauthorized {
  background-color: #fc565652 !important;
}

.rlm-public {
  background-color: #a3601452 !important;
}

.server-json {
  background-color: #ffa60030 !important;
}

.w2ui-icon-info:before {
  position: relative;
  top: 0;
}

.w2ui-tb-button:hover {
  background-color: #a3a3a385 !important;
}

.w2ui-tb-button.checked {
  background-color: #b3aeb2 !important;
}

.w2ui-icon-reload {
  position: relative !important;
  top: -15px !important;
  overflow: visible !important;
}

i.x {
  box-sizing: inherit;
  color: #8a8a8a;
  cursor: pointer;
  font-size: 1.5em;
  line-height: 1;
  position: absolute;
  bottom: -21px;
  right: calc(50% - 25px);
}

.add-parent {
  cursor: pointer;
}

.add-parent-input {
  width: 380px;
}

.add-child, .fa-unlink, .fa-plus {
  cursor: pointer;
}

.add-child-input {
  width: 380px;
}

.busy {
  background-color: #8080807f !important;
}

.arrow-up {
  border-bottom: 10px solid #000;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
}

.arrow-down {
  border-top: 10px solid #000;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
}

.arrow-left {
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 10px solid #000;
  width: 0;
  height: 0;
}

.arrow-right {
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 10px solid #000;
  width: 0;
  height: 0;
}

.loader {
  background-color: #0908;
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: 2s linear infinite spin;
}

#loader-counter:has(.loader) {
  z-index: 200;
  position: absolute;
  top: 10%;
  left: 40%;
}

#loader-counter:after {
  content: attr(data-depth);
  z-index: 201;
  font-size: 42px;
  position: relative;
  top: -101px;
  left: 10px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.1620f2d9.css.map */
